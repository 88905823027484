import request from '@/utils/request'

export function GetApplicantList() {
  return request({
    url: '/api/Reports/GetApplicantList',
    method: 'get',
  })
}

export function GetEmployedApplicants() {
  return request({
    url: '/api/Reports/GetEmployedApplicants',
    method: 'get',
  })
}

export function GetWaitingApplicants() {
  return request({
    url: '/api/Reports/GetWaitingApplicants',
    method: 'get',
  })
}

export function GetNegativeResultApplicants() {
  return request({
    url: '/api/Reports/GetNegativeResultApplicants',
    method: 'get',
  })
}

export function GetActiveFirmInventory() {
  return request({
    url: '/api/Reports/GetActiveFirmInventory',
    method: 'get',
  })
}

export function GetActiveRequestList() {
  return request({
    url: '/api/Reports/GetActiveRequestList',
    method: 'get',
  })
}

export function GetMainSummary() {
  return request({
    url: '/api/Reports/GetMainSummary',
    method: 'get',
  })
}

export function GetApplicantListByFilter(filtre) {
  const configHeaders = {
    'content-type': 'application/json',
  }
  return request({
    url: '/api/Reports/GetApplicantListByFilter',
    method: 'post',
    data: filtre,
    headers: configHeaders,
  })
}

export function GetEmployedApplicantsByFilter(filtre) {
  const configHeaders = {
    'content-type': 'application/json',
  }
  return request({
    url: '/api/Reports/GetEmployedApplicantsByFilter',
    method: 'post',
    data: filtre,
    headers: configHeaders,
  })
}

export function GetWaitingApplicantsByFilter(filtre) {
  const configHeaders = {
    'content-type': 'application/json',
  }
  return request({
    url: '/api/Reports/GetWaitingApplicantsByFilter',
    method: 'post',
    data: filtre,
    headers: configHeaders,
  })
}

export function GetNegativeResultApplicantsByFilter(filtre) {
  const configHeaders = {
    'content-type': 'application/json',
  }
  return request({
    url: '/api/Reports/GetNegativeResultApplicantsByFilter',
    method: 'post',
    data: filtre,
    headers: configHeaders,
  })
}

export function GetActiveFirmInventoryByFilter(filtre) {
  const configHeaders = {
    'content-type': 'application/json',
  }
  return request({
    url: '/api/Reports/GetActiveFirmInventoryByFilter',
    method: 'post',
    data: filtre,
    headers: configHeaders,
  })
}

export function GetActiveRequestListByFilter(filtre) {
  const configHeaders = {
    'content-type': 'application/json',
  }
  return request({
    url: '/api/Reports/GetActiveRequestListByFilter',
    method: 'post',
    data: filtre,
    headers: configHeaders,
  })
}

// http://192.168.0.70:9994/api/Reports/GetApplicantList
// http://localhost:9994/api/Reports/GetWaitingApplicants
